/*
  Flags specifically checked by the client
*/

// TODO: this should be passed in via SiteConstants->DOM instead
const url = process.env.PLAID_REDIRECT_URI || 'https://app.gonumeral.com';
const host = new URL(url).hostname;
export const isOnProduction = document.location.hostname === host;

export const publicRoutes = [
  '/maintenance',
  '/login-otp',
  '/forgot-password',
  '/email-sent',
  '/reset-password',
  '/reset-success',
  '/join',
  '/',
  '/login',
];
