import React, { useMemo, useState } from 'react';

import { Tabulator as TabulatorTypes } from 'react-tabulator/lib/types/TabulatorTypes';
import { createUseStyles } from 'react-jss';
import { useSearchParams } from 'react-router-dom';

import { gql, useQuery } from '@apollo/client';

import Banner from './Banner';
import DataGrid from './data_grid/DataGrid';
import { DataGridRow } from './data_grid/DataGridTypes.ts';
import ErrorMessage from './common/ErrorMessage';
import ExportButton from './CSVExportButton';
import LoaderAnimation from './common/LoaderAnimation';
import SectionNavHeader from './SectionNavHeader';
import { TCsvExportType } from '../../src/types/CsvExportTypes';
import { TLedgerAccountTypes } from '../../src/types/LedgerTypes';
import { TransactionTableFragment } from '../apollo/GraphQLFragments';
import { VendorDropdown } from '../features/cashTransactions/components/vendorDropdown/VendorDropdown.tsx';
import { useAppContext } from '../context/AppContext.tsx';
import { useRevenueTableContext } from '../context/RevenueTableContext.tsx';
import { useSharedStyles } from '../utils/CssUtil';
import { useTempBreadcrumbs } from './data_grid/hooks/useTempBreadcrumbs.ts';
import { useTempHighlightCalc } from './data_grid/hooks/useTempHighlightCalc.ts';
import { GetCashTransactionTableQuery, GetCashTransactionTableQueryVariables } from '../generated/graphql';
import { flattenData, getColumns } from './data_grid/utils/ColumnUtils';

const useStyles = createUseStyles({
  transactionsTable: {
    '&.tabulator': {
      fontSize: '13px',
    },
  },
});

const QUERY = gql`
  ${TransactionTableFragment}
  query GetCashTransactionTable(
    $start_time_ms: Float!
    $end_time_ms: Float
    $account_type: String!
    $is_negative: Boolean
    $dimension: String
    $dimension_values: String
    $page: Float
    $page_size: Float
    $vendor: String
    $external_account_id: String
  ) {
    cashTransactionTable(
      start_time_ms: $start_time_ms
      end_time_ms: $end_time_ms
      account_type: $account_type
      is_negative: $is_negative
      dimension: $dimension
      dimension_values: $dimension_values
      page: $page
      page_size: $page_size
      vendor: $vendor
      external_account_id: $external_account_id
    ) {
      ...TransactionTableFragment
    }
  }
`;

const CashTransactionsCardWithBanner: React.FunctionComponent = () => {
  const { isSupportNestedCashTxnPspRow, isRealCustomerCompany } = useAppContext();
  const { selectedCellPath } = useRevenueTableContext();
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const [params] = useSearchParams();

  const startTimeMs = params.get('st');
  const endTimeMs = params.get('et');
  const queryParams = {
    start_time_ms: startTimeMs ? Number(startTimeMs) : Date.now(),
    end_time_ms: endTimeMs ? Number(endTimeMs) : null,
    account_type: params.get('at') || TLedgerAccountTypes.DEFERRED_REVENUE_RECOGNIZED,
    is_negative: !!params.get('ne'),
    dimension: params.get('di'),
    dimension_values: params.get('dv'),
    external_account_id: params.get('eaid'),
    vendor: params.get('vin'),
    page: null,
    page_size: null,
  };

  const [isTablePaging, updateIsTablePaging] = useState(false);

  const {
    loading: isLoading,
    error,
    data,
    refetch,
  } = useQuery<GetCashTransactionTableQuery, GetCashTransactionTableQueryVariables>(QUERY, {
    variables: queryParams,
  });

  const highlights = useTempHighlightCalc(
    data?.cashTransactionTable.highlights || [],
    isLoading,
    selectedCellPath?.getValue()
  );
  const breadcrumbs = useTempBreadcrumbs(
    data?.cashTransactionTable?.sectionBreadcrumbs?.breadcrumbs || [],
    selectedCellPath,
    'Payments'
  );

  const timezoneLocation = data?.cashTransactionTable?.timezoneLocation;

  const pagerVariables = {
    currentPage: data?.cashTransactionTable?.currentPage || 1,
    pageCount: data?.cashTransactionTable?.pageCount || 1,
    pageSize: data?.cashTransactionTable?.pageSize || 50,
    refetchPage: (page: number, pageSize: number) => {
      updateIsTablePaging(true);
      refetch({
        ...queryParams,
        page,
        page_size: pageSize,
      }).then(() => {
        updateIsTablePaging(false);
      });
    },
  };

  const columns = data?.cashTransactionTable?.rows?.[0]
    ? [...getColumns(data.cashTransactionTable.rows[0], true, sharedClasses, timezoneLocation)]
    : [];

  const flattenedData = useMemo(
    () => (data?.cashTransactionTable?.rows ? flattenData(data?.cashTransactionTable.rows) : []),
    [data?.cashTransactionTable.rows]
  );

  const options: TabulatorTypes.Options = {
    layout: 'fitData',
    data: flattenedData,
    sortMode: 'remote',
    columnDefaults: {
      title: '',
      headerSort: false,
      minWidth: 60,
    },
    // use initialSort to account for querystring sort params
    persistence: true,
  };

  const vendors = data?.cashTransactionTable.vendors || [];

  const onFilterVendorData = (id: string) => {
    refetch({
      ...queryParams,
      page: 0,
      vendor: id,
      page_size: data?.cashTransactionTable?.pageSize || 50,
    });
  };

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <div className={sharedClasses.main}>
      <Banner highlights={highlights} />
      <div className={sharedClasses.contentWrapper}>
        {isLoading ? (
          <div className={sharedClasses.contentLoaderContainer}>
            <LoaderAnimation height={80} />
          </div>
        ) : (
          <>
            {!!vendors?.length && isSupportNestedCashTxnPspRow() && (
              <VendorDropdown vendors={vendors} onChange={onFilterVendorData} />
            )}
            <div className={sharedClasses.contentHeaderWrapper}>
              <SectionNavHeader
                sections={breadcrumbs}
                isLastItemClickable={data?.cashTransactionTable?.sectionBreadcrumbs?.shouldLinkLastBreadcrumb}
              />
              <div className={sharedClasses.contentHeaderUtils}>
                {isRealCustomerCompany ? undefined : <ExportButton type={TCsvExportType.FAKE} />}
              </div>
            </div>
            {isTablePaging ? (
              <div className={sharedClasses.contentLoaderContainer}>
                <LoaderAnimation height={80} />
              </div>
            ) : (
              <div className={sharedClasses.content}>
                <DataGrid
                  data={flattenedData as DataGridRow[]}
                  className={classes.transactionsTable}
                  columns={columns}
                  options={options}
                  shouldEnablePager={true}
                  pagerVariables={pagerVariables}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CashTransactionsCardWithBanner;
