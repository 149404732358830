import { gql } from '@apollo/client';

export const CellPillFragment = gql`
  fragment CellPillFragment on CellPill {
    label
    url
    category
    value
  }
`;

export const TransactionCellFragment = gql`
  ${CellPillFragment}
  fragment TransactionCellFragment on TransactionCell {
    label
    field
    content
    tooltip
    isLeftAligned
    url
    sortKey
    isSticky
    dateFilterStartField
    dateFilterEndField
    customClass
    customFormatter
    customMaxWidth
    pills {
      ...CellPillFragment
    }
  }
`;

export const TransactionCellRowFragment = gql`
  ${TransactionCellFragment}
  fragment TransactionCellRowFragment on TransactionCellRow {
    label
    labelUrl
    isSticky
    customClass
    rows {
      label
      labelUrl
      isSticky
      customClass
      cells {
        ...TransactionCellFragment
      }
    }
    cells {
      ...TransactionCellFragment
    }
  }
`;

export const TransactionCellNestedRowFragment = gql`
  ${TransactionCellFragment}
  fragment TransactionCellNestedRowFragment on TransactionCellRow {
    label
    labelUrl
    isSticky
    customClass
    rows {
      label
      labelUrl
      isSticky
      customClass
      rows @include(if: $includeNestedRow) {
        label
        labelUrl
        isSticky
        customClass
        cells {
          ...TransactionCellFragment
        }
      }
      cells {
        ...TransactionCellFragment
      }
    }
    cells {
      ...TransactionCellFragment
    }
  }
`;

export const TableHighlightFragment = gql`
  fragment TableHighlightFragment on TableHighlight {
    label
    type
    value
  }
`;

export const TransactionTableFragment = gql`
  ${TransactionCellRowFragment}
  ${TableHighlightFragment}
  fragment TransactionTableFragment on TransactionTable {
    timespanStart
    timespanEnd
    timezoneLocation
    granularity
    currency
    companyId
    highlights {
      ...TableHighlightFragment
    }
    sectionBreadcrumbs {
      key
      shouldLinkLastBreadcrumb
      breadcrumbs {
        label
        url
      }
    }
    vendors {
      id
      name
    }
    rows {
      ...TransactionCellRowFragment
    }
    pageCount
    currentPage
    pageSize
  }
`;

export const PriceListItemFragment = gql`
  fragment PriceListItemFragment on PriceListItemModel {
    id
    name
    description
    sku
    productCode
    productFamily
    saleType
    currency
    amount
    termIntervalUnit
    termIntervalLength
    state
    effectiveDate
    vendor
    updatedAt
  }
`;

export const ChartModuleDataFragment = gql`
  fragment ChartModuleDataFragment on ChartModuleData {
    title
    total
    change
    prevMonth
    link
    linkTitle
    currency
    currentMonthData {
      data
    }
    prevMonthData {
      data
    }
  }
`;

export const ChartSummaryDataFragment = gql`
  fragment ChartSummaryDataFragment on ChartSummaryData {
    data {
      cluster
      label
      x
      y
    }
    rawData {
      date
      dimension
      dimensionValue
      value
    }
    title
    yLabel
    xLabel
    currency
    rightAxisDimensions
  }
`;
