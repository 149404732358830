import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  row: {
    display: 'flex',
    marginBottom: '10px',
    alignItems: 'center',
    '& > div': {
      flex: 1,
    },
  },

  rowLabel: {
    fontSize: '14px',
    textAlign: 'right',
    paddingRight: '12px',
  },

  dropdownContainer: {
    minWidth: '240px',
  },
});
