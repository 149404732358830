// Common color palette. Add color hexes here instead of in css
const Colors = {
  FAINTEST_BLUE: '#dee8ff',
  FAINT_BLUE: '#8eb0f9',
  SOFT_SKY_BLUE: '#CAD6E7',
  MISTY_BLUE: '#CAD6E7',
  RADIANT_BLUE: '#4786F3',
  LIGHT_BLUE: '#568bff', //rgb(86, 139, 255)
  MAIN_BLUE: '#2567f6', //rgb(37, 103, 246)
  ACTIVE_TOGGLE_BLUE: '#a4c0fc', //rgb(164, 192, 252)
  MAIN_BLUE_HIGHLIGHT: '#5087ff', // rgb(80, 135, 255)
  LIGHT_BLUE_HIGHLIGHT: '#80a8ff', // rgb(128, 168, 255)
  // #9abcea

  WHITE: '#fff',
  OFFWHITE: '#dfe7f0',

  BLACK: '#000',

  FAINT_GRAY: '#eef3f8',
  LIGHT_GRAY: '#dde6f5',
  TABLE_HEADER_GRAY: '#cad5e8',
  MEDIUM_GRAY: '#8597b4',
  DARK_GRAY: '#22314a',
  SILVER_GRAY: '#BCBCBC',
  DISABLED: '#EAEAEA',
  DISABLED_BORDER: '#C9C9C9',
  STALE_DARK_GRAY: '#768295',
  DISABLED_TEXT: '#AFAFAF',
  NEUTRAL_GRAY: '#979797',
  SOFT_GRAY: '#9A9A9A',
  DEEP_GRAY: '#353535',

  WARNING_RED: '#a63f47',
  LIGHT_WARNING_RED: '#faa2a9',

  LIGHT_GREEN: '#a5faa2',
  DARK_GREEN: '#00540f',

  SHADOW: 'rgba(0,0,0,0.2)',

  PILL_AMAZON_SELLER_CENTRAL: '#965a00',
  PILL_AUTHORIZE_NET: '#2d2e00',
  PILL_BRAINTREE: '#5900b3', // rgb(89, 0, 179)
  PILL_CHARGEBEE: '#fdbd5e', // rgb(253, 189, 94)
  PILL_CHASE: '#003a96',
  PILL_DEFAULT: '#0d6203', // rgb(13, 98, 3)
  PILL_HOMEGROWN: '#be3825', // rgb(190, 56, 37)
  PILL_MODERN_TREASURY: '#194d00', // rgb(25, 77, 0)
  PILL_OANDA: '#856e06', // rgb(133, 110, 6)
  PILL_PAYMENTECH: '#525252',
  PILL_PAYPAL: '#0070e0', // rgb(0, 112, 224)
  PILL_RECONCILED_WITHOUT_EXCEPTIONS: '#194d00', // rgb(25, 77, 0)
  PILL_RECONCILED_WITH_EXCEPTIONS: '#be3825', // rgb(190, 56, 37)
  PILL_RECONCILED_WITH_EXCEPTIONS_RESOLVED: '#6d9472', // rgb(109, 148, 114)
  PILL_UNRECONCILED: '#808080', // rgb(128, 128, 128)
  PILL_RECONCILIATION_IN_PROGRESS: '#0033cc', // rgb(0, 51, 204)
  PILL_SALESFORCE: '#4d8eff', // rgb(77, 142, 255)
  PILL_SHOPIFY: '#96bf48', // rgb(150, 191, 72)
  PILL_STRIPE: '#a2bfff', // rgb(162, 191, 255)
  PILL_TELISPIRE: '#630000',
};

export default Colors;
