import React from 'react';
import { TRowFilterType } from '../../../../../src/types/BaseTypes.ts';
export const ZeroValueToggle = ({ onChange }: { onChange: (value: string) => void }) => {
  return (
    <div className="zero-value-rows-wrapper">
      <span>Rows:</span>
      <select
        className="rows-select"
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => onChange(event.target.value)}
      >
        <option value={TRowFilterType.ALL}>All Rows</option>
        <option value={TRowFilterType.ZERO}>Zero Value Rows</option>
        <option value={TRowFilterType.NON_ZERO}>Non Zero Value Rows</option>
      </select>
    </div>
  );
};
