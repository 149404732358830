import React, { useState } from 'react';

import { Vendor } from '../../../../generated/graphql.tsx';
import { VendorDropdownProps } from './type.ts';
import { useStyles } from './styles.ts';
import MenuDropdown, { MenuDropdownHorizontalAlignment } from '../../../../components/common/MenuDropdown.tsx';

export const VendorDropdown = ({ vendors, onChange, defaultVendorId = '' }: VendorDropdownProps) => {
  const [selectedVendorId, setSelectedVendorId] = useState<string>(defaultVendorId);
  const classes = useStyles();

  const onChangeHandler = (vendor: Vendor) => {
    if (vendor.id !== selectedVendorId) {
      setSelectedVendorId(vendor.id);
      onChange(vendor.id);
    }
  };

  const updatedVendors = vendors?.length ? [{ id: '', name: 'All' }, ...vendors] : [];

  const selectedVendor = updatedVendors?.find(({ id }) => id === selectedVendorId);

  return (
    <div className={classes.row}>
      <div className={classes.rowLabel}>Select Psp: </div>
      <div className={classes.dropdownContainer}>
        <MenuDropdown
          horizontalAlignment={MenuDropdownHorizontalAlignment.LEFT}
          items={updatedVendors.map((vendor) => ({
            label: vendor.name,
            onClick: () => onChangeHandler(vendor),
          }))}
          menuItemStyle={{
            fontSize: '14px',
            padding: '12px 15px',
          }}
        >
          {selectedVendor?.name ?? ''}
        </MenuDropdown>
      </div>
    </div>
  );
};
