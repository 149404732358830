import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Tabulator } from 'react-tabulator/lib/types/TabulatorTypes';
import { cloneDeep } from 'lodash';
import { useMatch } from 'react-router-dom';

import { DataGridRow } from '../components/data_grid/DataGridTypes.ts';
import { nestData } from '../components/data_grid/utils/ColumnUtils.ts';
import { useAppContext } from './AppContext.tsx';
import { useLazyQueryRevenueTable } from '../features/revenue/hooks/useQueryRevenueTable.ts';
import {
  GetRevenueTableQuery,
  GetRevenueTableV2Query,
  GetRevenueTableV2QueryVariables,
  GetTransformedRevenueQuery,
  PivotSettingModel,
  RevenueRowGroupOuput,
  RevenueSectionInput,
  UserModel,
  useCreatePivotMutation,
  useGetAllPivotsQuery,
  useGetAllTransactionsQuery,
  useGetPushToGlPreviewLazyQuery,
} from '../generated/graphql';
import { RevenueTableContextData, RevenueTableProviderProps, RowGroupMapped } from '../types/revenueTableContext.ts';
import { TCurrency, TIntervalUnit } from '../../src/types/BaseTypes';
import { removeTypename, transformedDraggedDimension } from '../features/revenue/utils/transformedDraggedDimension.ts';

export const RevenueTableContext = React.createContext<RevenueTableContextData>({} as RevenueTableContextData);

export const RevenueTableProvider = ({ children }: RevenueTableProviderProps) => {
  const { companyData } = useAppContext();
  const [selectedPivot, onSelectedPivot] = useState<PivotSettingModel | null>(null);
  const [selectedRevenueView, onSelectedRevenueView] = useState<PivotSettingModel | null>(null);
  const [dgRef, setDgRef] = useState<{ current: Tabulator }>();
  const [generatedPivot, setGeneratedPivot] = useState<any>([]);
  const [saveTransformedDimension, setSaveTransformedDimension] = useState<RevenueSectionInput>();
  const [selectedCellPath, setSelectedCellPath] = useState<Tabulator.CellComponent | null>(null);
  const [nestedData, setNestedData] = useState<DataGridRow[] | null>(null);
  const [aggregatedMappedAccounts, setAggregatedMappedAccounts] = useState<Array<RowGroupMapped>>([]);
  const [selectedYear, setSelectedYear] = useState<string | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
  const [rowFilter, setRowFilter] = useState<string | null>(null);
  const [transactionPivotId, setTransactionPivotId] = useState<string | null>(null);

  const isTransformedRevenuePage = !!useMatch('/app/transactions');

  const [createPivot] = useCreatePivotMutation();
  const cachedNestedRef = useRef<DataGridRow[] | null>(null);
  const companyName = companyData?.currentCompany?.name ?? '';
  const isSupportCustomPivot = ['Healthie', 'Scribd'].includes(companyName ?? '');

  const { data: allPivots } = useGetAllPivotsQuery({
    skip: !isSupportCustomPivot || isTransformedRevenuePage,
  });

  useGetAllTransactionsQuery({
    skip: !isSupportCustomPivot || !isTransformedRevenuePage,
    onCompleted(data) {
      setTransactionPivotId(data.getAllTransactions[0]?.id ?? '');
    },
  });

  const getRevenueTableData = (data: GetRevenueTableV2Query | GetRevenueTableQuery | GetTransformedRevenueQuery) => {
    return (
      (data as GetRevenueTableV2Query)?.revenueTableV2 ||
      (data as GetRevenueTableQuery)?.revenueTable ||
      (data as GetTransformedRevenueQuery)?.getTransformedRevenue
    );
  };

  const [fetch, { loading, error, data }] = useLazyQueryRevenueTable(
    {
      onCompleted: (data: GetRevenueTableQuery | GetRevenueTableV2Query | GetTransformedRevenueQuery) => {
        setNestedData(() => {
          const revenueTable = getRevenueTableData(data);
          return nestData(
            revenueTable.rowGroups,
            (revenueTable.currency as TCurrency) || TCurrency.USD
          ) as DataGridRow[];
        });
      },
    },
    isTransformedRevenuePage,
    transactionPivotId ?? ''
  );

  const onSetRowGroup = useCallback(
    (hierarchy: string[]) => {
      if (!allPivots?.getSystemDefaultPivot || !selectedYear) {
        return;
      }
      const generatedDraggedPivot = transformedDraggedDimension(
        allPivots.getSystemDefaultPivot.dimensionsDepth[0].rowGroups as RevenueRowGroupOuput[],
        [hierarchy, ...generatedPivot]
      );

      // @TODO: currently we only have single rowSection foe healthie & gabb
      setSaveTransformedDimension(() => ({
        label: allPivots.getSystemDefaultPivot.dimensionsDepth[0].label,
        rowGroups: generatedDraggedPivot,
      }));

      setGeneratedPivot((items) => {
        items.push(hierarchy);
        return items;
      });

      fetch({
        variables: {
          granularity: TIntervalUnit.MONTH,
          selectedYear: +selectedYear,
          pivotId: transactionPivotId ?? '',
          dimensions: [
            {
              // @TODO: currently we only have single rowSection foe healthie & gabb
              label: allPivots.getSystemDefaultPivot.dimensionsDepth[0].label,
              rowGroups: generatedDraggedPivot,
            },
          ],
        },
      });
    },
    [allPivots, fetch, generatedPivot, selectedYear, transactionPivotId]
  );

  const [fetchPushToGlPreview, { loading: glLoading }] = useGetPushToGlPreviewLazyQuery({
    onCompleted: (data) => {
      setAggregatedMappedAccounts(data.getPushToGlPreview.items);
    },
  });

  const fetchRevenueTable = useCallback(
    (variables?: Pick<GetRevenueTableV2QueryVariables, 'dimensions' | 'pivotId'>) => {
      if (!selectedYear) return;
      let updatedVariables = {
        granularity: TIntervalUnit.MONTH,
        selectedYear: +selectedYear,
        pivotId: '',
        ...variables,
      };

      if (isTransformedRevenuePage) {
        updatedVariables = {
          ...updatedVariables,
          pivotId: transactionPivotId,
        };
      }

      if (selectedPivot?.id) {
        updatedVariables = {
          ...updatedVariables,
          dimensions: selectedPivot.dimensionsDepth ? removeTypename(selectedPivot.dimensionsDepth) : [],
        };
      }

      fetch({
        variables: { ...updatedVariables, pivotId: updatedVariables.pivotId ?? '' },
      });
    },
    [fetch, selectedPivot, selectedYear, transactionPivotId, isTransformedRevenuePage]
  );

  const loadPushToGlPreview = useCallback(
    (yearMonth: string) => {
      fetchPushToGlPreview({
        variables: {
          yearMonth,
        },
      });
    },
    [fetchPushToGlPreview]
  );

  const onSavePivot = async (name: string, isDefault: boolean) => {
    try {
      await createPivot({
        variables: {
          name,
          isDefault,
          dimensionsDepth: [saveTransformedDimension] as RevenueSectionInput[],
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if ((!selectedYear && companyName) || (transactionPivotId === null && isTransformedRevenuePage)) return;
    fetchRevenueTable();
  }, [companyName, fetchRevenueTable, selectedYear, transactionPivotId, isTransformedRevenuePage]);

  const onExpandRows = () => {
    dgRef?.current.getRows().forEach((row) => {
      row.treeExpand();
    });
  };

  const onSetCustomPivot = (showPivotSettingOption: boolean) => {
    if (!isSupportCustomPivot) {
      return;
    }

    if (showPivotSettingOption) {
      cachedNestedRef.current = nestedData ? cloneDeep(nestedData) : null;
    } else {
      setNestedData(() => (cachedNestedRef.current ? [...cachedNestedRef.current] : null));
      cachedNestedRef.current = null;
      setTimeout(onExpandRows, 100);
    }
  };

  return (
    <RevenueTableContext.Provider
      value={{
        loading,
        error,
        dgRef,
        setDgRef,
        onSavePivot,
        selectedPivot,
        onSetCustomPivot,
        loadPushToGlPreview,
        selectedMonth,
        setSelectedMonth,
        selectedYear,
        setSelectedYear,
        nestedData,
        transactionPivotId,
        revenueTable: getRevenueTableData(
          data as GetRevenueTableV2Query | GetRevenueTableQuery | GetTransformedRevenueQuery
        ),
        setNestedData,
        companyData,
        onSetRowGroup,
        selectedCellPath,
        fetchRevenueTable,
        isSupportCustomPivot,
        aggregatedMappedAccounts,
        isLoading: loading || glLoading,
        selectedRevenueView,
        onSelectedRevenueView,
        onSetTransactionPivotId: setTransactionPivotId,
        isTransformedRevenuePage,
        currentUser: data?.currentUser as UserModel,
        onSelectedCellPath: setSelectedCellPath,
        onSelectedPivot: onSelectedPivot,
        rowFilter,
        setRowFilter,
      }}
    >
      {children}
    </RevenueTableContext.Provider>
  );
};

// HOC that wraps a component with the revenue table provider
export function withRevenueTableProvider<P extends JSX.IntrinsicAttributes>(Component: React.ComponentType<P>) {
  const EnhancedComponent = (props: P) => (
    <RevenueTableProvider>
      <Component {...props} />
    </RevenueTableProvider>
  );

  return EnhancedComponent;
}

// Hook that provides access to the revenue table context
export function useRevenueTableContext(): RevenueTableContextData {
  const context = React.useContext(RevenueTableContext);

  if (!context) {
    throw new Error('useRevenueTable must be used within a RevenueTableProvider');
  }

  return context;
}
