import React, { CSSProperties, ReactElement, useRef, useState } from 'react';

import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

import Colors from './Colors';
import useOutsideClick from '../../hooks/useOutsideClick';

const buttonHoverStyle = {
  background: Colors.MAIN_BLUE,
  border: `1px solid ${Colors.MAIN_BLUE}`,
  color: Colors.WHITE,
  '& $triangle': {
    borderLeft: `1px solid ${Colors.WHITE}`,
  },
};

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '100%',
  },

  menuButton: {
    alignItems: 'stretch',
    background: Colors.WHITE,
    border: `1px solid ${Colors.DARK_GRAY}`,
    borderRadius: '3px',
    color: Colors.DARK_GRAY,
    cursor: 'pointer',
    display: 'flex',
    fontSize: '14px',
    height: '38px',
    letterSpacing: '1px',
    paddingBottom: 0,
    paddingLeft: '15px',
    paddingRight: 0,
    paddingTop: 0,
    transition: 'background-color 0.1s',
    width: '100%',

    '&:hover': buttonHoverStyle,

    '&[disabled]': {
      background: Colors.MEDIUM_GRAY,
      border: `1px solid ${Colors.MEDIUM_GRAY}`,
      cursor: 'default',

      '&:hover': {
        background: Colors.MEDIUM_GRAY,
      },
    },
  },

  menuButtonExpanded: {
    ...buttonHoverStyle,

    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    cursor: 'default',
  },

  label: {
    textAlign: 'left',
    paddingRight: '15px',
    paddingTop: '10px',
    whiteSpace: 'nowrap',
    width: '100%',
  },

  triangle: {
    borderLeft: `1px solid ${Colors.DARK_GRAY}`,
    fontSize: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '12px',
  },

  dropdown: {
    position: 'absolute',

    backgroundColor: Colors.WHITE,
    border: `1px solid ${Colors.STALE_DARK_GRAY}`,
    borderTop: 'none',
    borderBottomLeftRadius: '3px',
    borderBottomRightRadius: '3px',
    color: Colors.DARK_GRAY,
    minWidth: '140px',
    top: '37px',
    width: 'calc(100% - 2px)',
    zIndex: 1,
  },

  dropdownRightAligned: {
    borderTopLeftRadius: '3px',
    right: 0,
  },

  dropdownLeftAligned: {
    borderTopRightRadius: '3px',
    left: 0,
  },

  dropdownSubRightAligned: {
    right: '100%',
    top: 0,
  },

  dropdownSubLeftAligned: {
    left: '100%',
    top: 0,
  },

  dropdownItemContainer: {
    position: 'relative',

    '&:hover > $dropdownItem': {
      background: (props) => (props?.highlightColor ? Colors[props?.highlightColor] : Colors.MAIN_BLUE),
      color: Colors.WHITE,
    },
  },

  dropdownItem: {
    color: Colors.DARK_GRAY,
    cursor: 'pointer',
    display: 'block',
    padding: '15px 24px',
    transition: 'all 0.1s',
  },

  dropdownItemExpandable: {
    paddingRight: '20px',
    '&:after': {
      content: '"▷"',
      float: 'right',
    },
  },
});

export enum MenuDropdownHorizontalAlignment {
  LEFT,
  RIGHT,
}

type DropdownProps = {
  horizontalAlignment: MenuDropdownHorizontalAlignment;
  isExpanded: boolean;
  items: MenuItem[];
  itemStyle?: CSSProperties;
  dropdownStyle?: CSSProperties;
  width?: number;
  level?: number;
  highlightColor?: keyof typeof Colors;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  colapseFn?: () => void;
};

const Dropdown: React.FunctionComponent<DropdownProps> = ({
  isExpanded,
  colapseFn,
  items,
  itemStyle,
  horizontalAlignment,
  level = 1,
  width,
  onMouseEnter,
  onMouseLeave,
  dropdownStyle,
  highlightColor = 'MAIN_BLUE',
}: DropdownProps) => {
  const style = width ? { width: `${width}px` } : {};
  const classes = useStyles({ highlightColor });
  const [menuItemsExpanded, updateMenuItemsExpanded] = useState(items.map(() => false));
  return isExpanded ? (
    <div
      className={[
        classes.dropdown,
        horizontalAlignment === MenuDropdownHorizontalAlignment.RIGHT
          ? level > 1
            ? classes.dropdownSubRightAligned
            : classes.dropdownRightAligned
          : level > 1
          ? classes.dropdownSubLeftAligned
          : '',
      ].join(' ')}
      style={{ ...style, ...dropdownStyle }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {items.map(({ items: subItems, href, label, onClick }, idx) => {
        const itemClasses = [
          classes.dropdownItem,
          subItems && subItems.length ? classes.dropdownItemExpandable : '',
        ].join(' ');
        const expandFn = () => updateMenuItemsExpanded(items.map((_item, i) => i === idx));
        const collapseFn = () => updateMenuItemsExpanded(items.map(() => false));
        const closeAndClick = () => {
          if (isExpanded && colapseFn && onClick) {
            colapseFn();
            onClick();
          }
        };
        return (
          <div
            className={classes.dropdownItemContainer}
            key={`dropdown-item-container-${level}-${idx}`}
            onMouseEnter={expandFn}
            onMouseLeave={collapseFn}
            onClick={collapseFn}
          >
            {href ? (
              <Link className={itemClasses} style={itemStyle} key={`dropdown-item-${level}-${idx}`} to={href}>
                {label}
              </Link>
            ) : (
              <div
                className={itemClasses}
                style={itemStyle}
                key={`dropdown-item-${level}-${idx}`}
                onClick={closeAndClick}
              >
                {label}
              </div>
            )}
            {subItems && subItems.length ? (
              <Dropdown
                isExpanded={menuItemsExpanded[idx]}
                horizontalAlignment={horizontalAlignment}
                items={subItems}
                itemStyle={itemStyle}
                width={width}
                level={level + 1}
              />
            ) : null}
          </div>
        );
      })}
    </div>
  ) : null;
};

export type MenuItem = {
  href?: string;
  label: string;
  onClick?: () => void;
  items?: MenuItem[];
};

type MenuDropdownProps = {
  children: ReactElement | string | number;
  horizontalAlignment?: MenuDropdownHorizontalAlignment;
  isDisabled?: boolean;
  items: MenuItem[];
  menuItemStyle?: CSSProperties;
  dropdownStyle?: CSSProperties;
  width?: number;
  highlightColor?: keyof typeof Colors;
};

const MenuDropdown: React.FunctionComponent<MenuDropdownProps> = ({
  children,
  isDisabled,
  items,
  horizontalAlignment = MenuDropdownHorizontalAlignment.RIGHT,
  menuItemStyle,
  width,
  highlightColor,
  dropdownStyle,
}: MenuDropdownProps) => {
  const classes = useStyles();

  const [isExpanded, updateIsExpanded] = useState(false);
  const ref = useRef(null);
  useOutsideClick(ref, () => {
    updateIsExpanded(false);
  });

  return (
    <div className={classes.container} ref={ref}>
      <button
        className={[classes.menuButton, isExpanded ? classes.menuButtonExpanded : ''].join(' ')}
        disabled={isDisabled}
        onClick={isDisabled ? undefined : () => updateIsExpanded(!isExpanded)}
        style={width ? { width: `${width}px` } : {}}
      >
        <span className={classes.label}>{children}</span>
        <span className={classes.triangle}>{isExpanded ? '△' : '▽'}</span>
      </button>
      <Dropdown
        dropdownStyle={dropdownStyle}
        highlightColor={highlightColor}
        horizontalAlignment={horizontalAlignment}
        isExpanded={isExpanded}
        colapseFn={() => updateIsExpanded(false)}
        items={items}
        itemStyle={menuItemStyle}
      />
    </div>
  );
};

export default MenuDropdown;
