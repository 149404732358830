import React from 'react';

import { useRevenueTableContext } from '../../../../context/RevenueTableContext.tsx';
import MenuDropdown, { MenuDropdownHorizontalAlignment } from '../../../../components/common/MenuDropdown.tsx';

export const YearDropDown: React.FunctionComponent = () => {
  const { selectedYear, setSelectedYear, companyData } = useRevenueTableContext();

  const years: number[] = [];
  const getDefaultDashboardYear = (isRealCompany: boolean | undefined, companyName?: string) => {
    if (companyName === 'PayZen 2023') {
      return 2023;
    }
    return isRealCompany ? 2024 : 2022;
  };

  const companyName = companyData?.currentCompany?.name;
  const oldestYear = companyName == 'CaptionsAI' ? 2021 : 2022;

  for (let i = oldestYear; i <= new Date().getFullYear(); i++) {
    years.push(i);
  }

  const dropdownYears = years.map((year) => ({
    label: `FY: ${year}`,
    onClick: () => setSelectedYear(year.toString()),
  }));

  return (
    <div className="year-wrapper">
      <span className="year-label">Year :</span>
      <MenuDropdown
        menuItemStyle={{
          height: '42px',
          boxSizing: 'border-box',
          padding: '0 24px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
        highlightColor="MISTY_BLUE"
        items={dropdownYears}
        horizontalAlignment={MenuDropdownHorizontalAlignment.LEFT}
      >
        {`FY: ${selectedYear || getDefaultDashboardYear(true, companyName).toString()}`}
      </MenuDropdown>
    </div>
  );
};
