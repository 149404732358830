import React, { useEffect, useMemo, useState } from 'react';

import { Tabulator as TabulatorTypes } from 'react-tabulator/lib/types/TabulatorTypes';
import { createUseStyles } from 'react-jss';
import { useSearchParams } from 'react-router-dom';

import { gql } from '@apollo/client';
import { useAppContext } from '../context/AppContext.tsx';
import Banner from './Banner';
import DataGrid from './data_grid/DataGrid';
import ExportButton from './CSVExportButton';
import LoaderAnimation from './common/LoaderAnimation';
import SectionNavHeader from './SectionNavHeader';
import { TCsvExportType } from '../../src/types/CsvExportTypes';
import { TIntervalUnit } from '../../src/types/BaseTypes';
import { TSearchBarLazyQueryFn } from './SearchBar';
import { TransactionTableFragment } from '../apollo/GraphQLFragments';
import { useRevenueTableContext } from '../context/RevenueTableContext.tsx';
import { useSharedStyles } from '../utils/CssUtil';
import { useTempBreadcrumbs } from './data_grid/hooks/useTempBreadcrumbs.ts';
import { useTempHighlightCalc } from './data_grid/hooks/useTempHighlightCalc.ts';
import { flattenData, getColumns } from './data_grid/utils/ColumnUtils';
import {
  useGetRevenueTransactionsRecognitionEventsCountHighlightLazyQuery,
  useGetTransactionTableQuery,
  useGetTransactionTableV2Query,
} from '../generated/graphql';
import { ZeroValueToggle } from '../features/revenue/components/ZeroValueRowsToggle/ZeroValueToggle.tsx';
import { DataGridRow } from './data_grid/DataGridTypes.ts';

const useStyles = createUseStyles({
  transactionsTable: {
    '&.tabulator': {
      fontSize: '13px',
    },
  },
});

// v2query
gql`
  query GetRevenueTransactionsRecognitionEventsCountHighlight(
    $month: Float!
    $year: Float!
    $accounts_str: String!
    $model_table_name: String
    $dimensions_flat_map_str: String
    $rollupId: String
  ) {
    revenueTransactionsRecognitionEventsCountHighlight(
      month: $month
      year: $year
      accounts_str: $accounts_str
      model_table_name: $model_table_name
      dimensions_flat_map_str: $dimensions_flat_map_str
      rollupId: $rollupId
    ) {
      label
      type
      value
    }
  }

  ${TransactionTableFragment}
  query GetTransactionTableV2(
    $day: Float
    $month: Float!
    $year: Float!
    $accounts_str: String!
    $parent_row_labels_str: String!
    $dimensions_flat_map_str: String
    $page: Float
    $page_size: Float
    $rollupId: String
    $model_table_name: String
    $show_invoiced_drilldown: Boolean
    $isRollforwardStartingBalance: Boolean
    $isRollforwardEndingBalance: Boolean
    $rowFilter: String
  ) {
    revenueTransactionTableV2(
      day: $day
      month: $month
      year: $year
      accounts_str: $accounts_str
      parent_row_labels_str: $parent_row_labels_str
      dimensions_flat_map_str: $dimensions_flat_map_str
      page: $page
      page_size: $page_size
      model_table_name: $model_table_name
      show_invoiced_drilldown: $show_invoiced_drilldown
      rollupId: $rollupId
      isRollforwardStartingBalance: $isRollforwardStartingBalance
      isRollforwardEndingBalance: $isRollforwardEndingBalance
      rowFilter: $rowFilter
    ) {
      ...TransactionTableFragment
    }

    currentUser {
      company {
        isRealCustomerCompany
      }
    }
  }

  ${TransactionTableFragment}
  query GetTransactionTable(
    $start_time_ms: Float!
    $granularity: String!
    $account_type: String
    $end_time_ms: Float
    $dimension: String
    $dimension_values: String
    $page: Float
    $page_size: Float
    $core_model_table_name: String
  ) {
    revenueTransactionTable(
      start_time_ms: $start_time_ms
      granularity: $granularity
      account_type: $account_type
      end_time_ms: $end_time_ms
      dimension: $dimension
      dimension_values: $dimension_values
      page: $page
      page_size: $page_size
      core_model_table_name: $core_model_table_name
    ) {
      ...TransactionTableFragment
    }

    currentUser {
      company {
        isRealCustomerCompany
      }
    }
  }
`;

const TransactionsCardWithBanner: React.FunctionComponent = () => {
  const { selectedCellPath } = useRevenueTableContext();
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const [params] = useSearchParams();
  const { hasZeroAmountRowFilter } = useAppContext();
  const startTimeMs = params.get('st');
  const endTimeMs = params.get('et');
  const version = params.get('v') || '1';
  const v1QueryParams = {
    start_time_ms: startTimeMs ? Number(startTimeMs) : Date.now(),
    end_time_ms: endTimeMs ? Number(endTimeMs) : null,
    granularity: params.get('tt') || TIntervalUnit.MONTH,
    account_type: params.get('at'),
    dimension: params.get('di'),
    dimension_values: params.get('dv'),
    page: 0,
    page_size: 25,
    core_model_table_name: params.get('ct'),
  };

  const v2QueryParams = {
    page: 0,
    page_size: 25,
    day: params.get('d') ? Number(params.get('d')) : undefined,
    month: Number(params.get('m')),
    year: Number(params.get('y')),
    accounts_str: params.get('at') || '',
    parent_row_labels_str: params.get('prl') || '',
    dimensions_flat_map_str: params.get('dfm'),
    model_table_name: params.get('mt'),
    show_invoiced_drilldown: params.get('si') === 'true',
    rollupId: params.get('ri') || '',
    isRollforwardStartingBalance: params.get('rsb') === 'true',
    isRollforwardEndingBalance: params.get('reb') === 'true',
    rowFilter: params.get('rf') ? params.get('rf') : undefined,
  };

  console.log('v2QueryParams', v2QueryParams);

  const queryFn = version === '2' ? useGetTransactionTableV2Query : useGetTransactionTableQuery;
  const selectedQueryParams = version === '2' ? v2QueryParams : v1QueryParams;
  const [rowFilter, setRowFilter] = useState<string | null>(null);
  const variables = selectedQueryParams as Parameters<typeof useGetTransactionTableV2Query>[0]['variables'] &
    Parameters<typeof useGetTransactionTableQuery>[0]['variables'];
  const rollForwardBalance = v2QueryParams.isRollforwardStartingBalance || v2QueryParams.isRollforwardEndingBalance;
  const {
    loading: isLoading,
    error,
    data,
    refetch,
  } = queryFn({
    variables,
    fetchPolicy: 'network-only',
  }) as ReturnType<typeof useGetTransactionTableV2Query> & ReturnType<typeof useGetTransactionTableQuery>;

  const [fetchTriggerEventCount, { data: triggerEventCountHighlight, loading: triggerEventCountIsLoading }] =
    useGetRevenueTransactionsRecognitionEventsCountHighlightLazyQuery({ variables: v2QueryParams });
  useEffect(() => {
    if (version === '2') {
      fetchTriggerEventCount();
    }
  }, [version]);

  const [isTablePaging, updateIsTablePaging] = useState(false);

  const fetchTransactionsTable = (rowFilter: string) => {
    const page = revenueTableData?.currentPage;
    setRowFilter(rowFilter);
    const updatedVariables = {
      ...variables,
      page: page ? page : 0,
      rowFilter,
    };
    refetch({
      ...updatedVariables,
    });
  };

  const revenueTableData = data?.revenueTransactionTableV2 || data?.revenueTransactionTable;
  const timezoneLocation = revenueTableData?.timezoneLocation;

  // if we know the actual number of pages, store that here. this is on a different query than the table,
  // so it won't get refreshed when page is changed
  const pageCountFromHighlight = triggerEventCountHighlight
    ? Math.ceil(
        Number(triggerEventCountHighlight.revenueTransactionsRecognitionEventsCountHighlight.value.replace(/,/g, '')) /
          (revenueTableData?.pageSize || 50)
      )
    : null;

  const tempBreadcrumbs = useTempBreadcrumbs(
    revenueTableData?.sectionBreadcrumbs?.breadcrumbs || [],
    selectedCellPath,
    'Transactions'
  );

  const tableHighlights = useTempHighlightCalc(
    revenueTableData?.highlights || [],
    isLoading,
    selectedCellPath?.getValue()
  );
  const countHighlight = triggerEventCountHighlight
    ? {
        ...triggerEventCountHighlight.revenueTransactionsRecognitionEventsCountHighlight,
        header: triggerEventCountHighlight.revenueTransactionsRecognitionEventsCountHighlight.label,
      }
    : { value: '', header: '', type: '', isLoading: triggerEventCountIsLoading };

  const pagerVariables = {
    currentPage: revenueTableData?.currentPage || 1,
    pageCount: pageCountFromHighlight || revenueTableData?.pageCount || 1,
    pageSize: revenueTableData?.pageSize || 50,
    refetchPage: (page: number, pageSize: number) => {
      updateIsTablePaging(true);
      const queryParamsToUse = version === '2' ? v2QueryParams : v1QueryParams;
      refetch({
        ...queryParamsToUse,
        page,
        page_size: pageSize,
        rowFilter,
      }).then(() => {
        updateIsTablePaging(false);
      });
    },
  };

  const columns = revenueTableData?.rows?.[0]
    ? [...getColumns(revenueTableData.rows[0], true, sharedClasses, timezoneLocation, undefined)]
    : [];

  const flattenedData = useMemo(
    () => (revenueTableData?.rows ? flattenData(revenueTableData.rows) : []) || [],
    [revenueTableData?.rows]
  );

  const options: TabulatorTypes.Options = {
    layout: 'fitData',
    sortMode: 'remote',
    columnDefaults: {
      title: '',
      headerSort: false,
      minWidth: 60,
    },
    // use initialSort to account for querystring sort params
    persistence: true,
  };

  if (error) {
    return <div className={sharedClasses.main}>Error: {error.message}</div>;
  }

  return (
    <div className={sharedClasses.main}>
      <Banner
        showSearchBar={version === '2'}
        searchBarQueryFn={version === '2' ? TSearchBarLazyQueryFn.SEARCH_REVENUE_TRANSACTION_DRILLDOWN_V2 : undefined}
        highlights={[...tableHighlights, countHighlight].map((highlight) => ({
          header: highlight.header || '',
          isLoading: highlight.isLoading,
          value: highlight.value || '',
        }))}
      />
      <div className={sharedClasses.contentWrapper}>
        {isLoading ? (
          <div className={sharedClasses.contentLoaderContainer}>
            <LoaderAnimation height={80} />
          </div>
        ) : (
          <>
            <div className={sharedClasses.contentHeaderWrapper}>
              <SectionNavHeader
                sections={tempBreadcrumbs}
                isLastItemClickable={revenueTableData?.sectionBreadcrumbs?.shouldLinkLastBreadcrumb}
              />
              <div className={sharedClasses.contentHeaderUtils}>
                {hasZeroAmountRowFilter() && rollForwardBalance && (
                  <ZeroValueToggle onChange={fetchTransactionsTable} />
                )}
                {version === '2' ? (
                  <ExportButton
                    type={TCsvExportType.REVENUE_TRANSACTION_REPORT_V2}
                    url={'/download/csv-revenue-transactions-v2?' + params.toString()}
                  />
                ) : (
                  <ExportButton type={TCsvExportType.REVENUE_TRANSACTION_REPORT} searchParams={params} enabled />
                )}
              </div>
            </div>
            {isTablePaging ? (
              <div className={sharedClasses.contentLoaderContainer}>
                <LoaderAnimation height={80} />
              </div>
            ) : (
              <div className={sharedClasses.content}>
                <DataGrid
                  className={classes.transactionsTable}
                  data={flattenedData as DataGridRow[]}
                  columns={columns}
                  options={options}
                  shouldEnablePager={true}
                  pagerVariables={pagerVariables}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TransactionsCardWithBanner;
