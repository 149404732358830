import React, { useEffect, useState } from 'react';

import classnames from 'classnames';

import FormButton from './common/FormButton';
import { LoginState } from '../features/auth/types';
import SecondaryLink from './common/SecondaryLink';
import getAssetPath from '../utils/AssetPathUtil';
import { isOnProduction } from '../utils/Constants';
import { useAppContext } from '../context/AppContext.tsx';
import { useLoginUserMutation } from '../generated/graphql';
import { useSharedStyles } from '../utils/CssUtil';
import { useStyles } from '../features/auth/styles';

const Login: React.FunctionComponent = () => {
  const { onLoginSuccess, onResetAuth } = useAppContext();
  const [stateValue, setStateValue] = useState<LoginState>(LoginState.Initial);
  const [errorValue, setErrorStateValue] = useState<string | null>(null);
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const [email, onSetEmail] = useState('');
  const [password, onSetPassword] = useState('');

  const [getLoginUser, { error: getLoginUserErrorStr }] = useLoginUserMutation();

  const continueFn = async () => {
    setStateValue(LoginState.Submitting);
    const { data: user } = await getLoginUser({
      variables: {
        email,
        password,
      },
    });

    if (user?.loginUser?.isSuccess) {
      setStateValue(LoginState.Succeeded);
      setErrorStateValue(null);
      onLoginSuccess(user.loginUser);
    } else {
      setStateValue(LoginState.Failed);
      setErrorStateValue(
        user?.loginUser?.error || getLoginUserErrorStr?.message || 'Unable to log in at the time. Try again later.'
      );
    }
  };

  const disableContinue = stateValue === LoginState.Submitting || !email || !password;

  const loginWithGoogle = () => (window.location.href = '/auth');

  useEffect(() => {
    onResetAuth();
  }, []);

  return (
    <div className={classes.loginContainer}>
      <form onSubmit={() => continueFn()}>
        <div className={classnames(sharedClasses.banner, sharedClasses.loginBanner)}></div>
        <div className={classnames(sharedClasses.contentWrapper, classes.contentWrapper)}>
          <div className={classes.loginLogoContainer}>
            <img src={`${getAssetPath()}/images/logo_small.png`} height={48} />
            <p>Sign in to get started</p>
          </div>
          {errorValue ? <div className={classes.error}>{errorValue}</div> : null}
          <div className={classes.loginRow}>
            <span>Email:</span>
          </div>
          <div className={classes.loginRow}>
            <input
              type="text"
              name="email"
              disabled={stateValue === LoginState.Submitting}
              placeholder="you@company.com"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSetEmail(e.target.value)}
            />
          </div>
          <div className={classes.loginRow}>
            <span>Password:</span>
          </div>
          <div className={classes.loginRow}>
            <input
              type="password"
              name="password"
              disabled={stateValue === LoginState.Submitting}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSetPassword(e.target.value)}
            />
          </div>
          <div className={classes.loginRow}>
            <FormButton isDisabled={disableContinue} onClick={() => continueFn()}>
              Continue
            </FormButton>
          </div>
          {isOnProduction ? undefined : (
            <div className={classes.loginRow}>
              <FormButton isDisabled={stateValue === LoginState.Submitting} onClick={loginWithGoogle}>
                Login with Google
              </FormButton>
            </div>
          )}
          <div className={classes.loginRow} style={{ textAlign: 'center' }}>
            <SecondaryLink to="/forgot-password">Forgot Password?</SecondaryLink>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
